import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import { useContext, useEffect } from 'react'

import {
  AdminPanelPageSlug,
  DashboardPageSlug,
  LoginPageSlug
} from '@appConstants'

import { IAMContext, TIAMContext } from 'context'

export default function Home() {
  const router = useRouter()
  const { data: session } = useSession()
  const {
    IAMData: { userType }
  } = useContext(IAMContext) as TIAMContext

  useEffect(() => {
    if (!session) {
      router.push(LoginPageSlug)
    } else {
      const route =
        userType === 'admin' ? AdminPanelPageSlug : DashboardPageSlug
      router.push(route)
    }
  }, [router, session, userType])
  // return (
  //   <>
  //     <Head>
  //       <title>Alaris Acquistions</title>
  //       <meta name='description' content='' />
  //       <meta name='viewport' content='width=device-width, initial-scale=1' />
  //       <link rel='icon' href='/favicon.ico' />
  //     </Head>
  //   </>
  // )
}
